/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { AuthUser } from '.';
import { useLocale } from '../../components/Providers/localeProvider';
import env from '../../env';
import { fetcher, useFetchApi } from '../../utils/fetchUtils';
import { buildUrl } from '../../utils/urlUtils';

type UserHookOptions = {
  required?: boolean;
  skipFetch?: boolean;
  initialData?: AuthUser;
};

export const useUser = (options?: UserHookOptions) => {
  // Stale while revalidate the user
  const { data, error, mutate: setUser, revalidate } = useSWR<AuthUser>(
    options?.skipFetch ? null : '/api/user',
    fetcher,
    {
      initialData: options?.initialData
    }
  );

  const accessToken = useMemo(() => data?.accessToken, [data]);
  const user = useMemo(() => data?.user, [data]);
  const isLoading = useMemo(() => !error && !data, [error, data]);

  const router = useRouter();
  const locale = useLocale();

  useEffect(() => {
    const errorMessage: string | undefined = error?.data?.error?.message;

    const isAuthError =
      // In dev it won't throw "unauthorized"
      errorMessage?.includes('Invalid session payload') || errorMessage?.includes('Unauthorized');

    // If this is a required page, and we finished loading, but still don't have the user
    if (options?.required && !user && !isLoading && !!isAuthError) {
      router.push('/api/login', `/api/login`);
    }
  }, [user, error, router, locale, isLoading, options]);

  const clearCache = useCallback(async () => {
    await setUser(undefined, true);
    await revalidate();
  }, [setUser, revalidate]);

  return { accessToken, user, isLoading, error, setUser, clearCache };
};

export const useSendPasswordCodeEmail = <Body = Record<string, any>>(locale?: string) =>
  useFetchApi<Body>(
    buildUrl(
      `${env().NEXT_PUBLIC_AUTH_PASSWORD_EMAIL_ENDPOINT?.replace('/locale/', `/${locale || `en`}/`)}`,
      env().NEXT_PUBLIC_AUTH_ORIGIN,
      true
    ) as string,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  );

export const useConfirmNewPassword = <Body = Record<string, any>>(locale?: string) =>
  useFetchApi<Body>(
    buildUrl(
      `${env().NEXT_PUBLIC_AUTH_PASSWORD_CHANGE_ENDPOINT?.replace('/locale/', `/${locale || `en`}/`)}`,
      env().NEXT_PUBLIC_AUTH_ORIGIN,
      true
    ) as string,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
  );
