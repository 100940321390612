/* eslint-disable @typescript-eslint/no-explicit-any */
import { NextPageContext } from 'next';
import Head from 'next/head';
import React, { useMemo } from 'react';
import { ApolloClient, ApolloProvider, getDataFromTree, NormalizedCacheObject } from 'react-apollo';
import logging from '../../utils/loggingUtils';

const getApolloHOC = (
  initializeApollo: (
    initialState?: NormalizedCacheObject | null,
    client?: ApolloClient<NormalizedCacheObject>
  ) => ApolloClient<NormalizedCacheObject>
) => {
  const apolloHoc = (AppPage: any, initialState?: NormalizedCacheObject | null) => {
    // Create a simple functional component
    const WithApollo = (props: any) => {
      const { initialApolloState, apolloClient } = props;
      const client = useMemo(() => initializeApollo(initialApolloState, apolloClient), [
        initialApolloState,
        apolloClient
      ]);

      return (
        <div id="apollo-provider">
          <ApolloProvider client={client}>
            <AppPage {...props} />
          </ApolloProvider>
        </div>
      );
    };

    // Set the name of this component to the name of the HOC
    WithApollo.displayName = `WithApollo(${(AppPage as any).displayName || AppPage.name || 'Unknown'})`;

    const getInitialProps = AppPage.getInitialProps;
    if (getInitialProps) {
      WithApollo.getInitialProps = async (ctx: NextPageContext) => {
        // Call the app's getInitialProps
        const pageProps = await getInitialProps(ctx);

        // If we are in server side and don't have any state, let's fetch the state
        if (!pageProps?.initialApolloState && typeof window === 'undefined') {
          // If the page has already finished, just return the props
          if (ctx.res && (ctx.res.headersSent || ctx.res.finished)) {
            return pageProps;
          }

          // Instantiate the apollo client
          const apolloClient = initializeApollo(initialState);

          // Get the app component and the context
          const { AppTree } = ctx;
          try {
            await getDataFromTree(
              <ApolloProvider client={apolloClient}>
                <AppTree pageProps={{ ...pageProps, apolloClient }} />
              </ApolloProvider>
            );
          } catch (error) {
            // If an error ocurred
            logging.error(error, 'Graphql error when running [getDataFromTree]');
          } finally {
            Head.rewind();
          }

          const initialApolloState = apolloClient.cache.extract();

          // Return the app props after the call
          return {
            ...pageProps,
            initialApolloState
          };
        } else {
          // Return the app props after the call
          return pageProps;
        }
      };
    }

    return WithApollo;
  };
  return apolloHoc;
};

export default getApolloHOC;
