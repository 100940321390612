import { onError } from '@apollo/link-error';
import logging from '../../utils/loggingUtils';

export const errorHandlerLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, locations, path, extensions } = error;
      const code = (extensions?.code || '') as string;
      logging.error(
        error,
        `[GraphQL error]: ${message}${
          locations ? `. Location in query string: ${JSON.stringify([...locations])}` : ''
        }${path ? `. Path: ${path}` : ''}${code ? `. Code: ${code}` : ''}`,
        {
          message,
          locations,
          path,
          code
        }
      );
    });
  if (networkError) logging.error(networkError, `[Network error]`);
});
