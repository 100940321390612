export const buildUrl = (path?: string, base?: string, ignoreBasePathname?: boolean) => {
  if (!path && !base) return undefined;
  if (!path) return base;

  // Make sure path always start with a /
  if (path && path[0] !== '/') {
    path = `/${path}`;
  }

  try {
    // If we have the base path
    if (base) {
      // Makes an url with the base path
      const baseUrlClass = new URL(base);

      // The url must still keep the base pathname.
      // If the pathname is not empty(different of /), we prepend it
      return new URL(
        `${!ignoreBasePathname ? (baseUrlClass.pathname !== '/' ? baseUrlClass.pathname : '') : ''}${path}`,
        base
      ).toString();
    } else {
      return new URL(path, base).toString();
    }
  } catch (err) {
    console.warn('Did not manage to create url using URL class, using string composition fallback', err);
    return encodeURIComponent(`${base?.trim() || ''}${path?.trim()}`);
  }
};

export const buildUrlClass = (path: string, base?: string, ignoreBasePathname?: boolean) => {
  // Make sure path always start with a /
  if (path && path[0] !== '/') {
    path = `/${path}`;
  }

  // If we have the base path
  if (base) {
    // Makes an url with the base path
    const baseUrlClass = new URL(base);

    // The url must still keep the base pathname.
    // If the pathname is not empty(different of /), we prepend it
    return new URL(
      `${!ignoreBasePathname ? (baseUrlClass.pathname !== '/' ? baseUrlClass.pathname : '') : ''}${path}`,
      base
    );
  } else {
    return new URL(path);
  }
};
