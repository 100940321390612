import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject, split } from 'react-apollo';
import { errorHandlerLink } from './links/errorHandlerLink';
import getApolloHOC from '../lib/apollo/withApollo';
import env from '../env';
import { attachEnvVariablesToQueries } from './links/attachEnvVariablesToQueries';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

// Ref: https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js

let apolloClient: ApolloClient<NormalizedCacheObject>;

const createApolloClient = () => {
  const backendLink = new HttpLink({
    uri: env().NEXT_PUBLIC_API_URI
  });

  const cmsLink = new HttpLink({
    uri: env().NEXT_PUBLIC_CMS_URI
  });

  // Http link is a sum of both links.
  const httpLink = split((operation) => operation.getContext().endpoint === 'cms', cmsLink, backendLink);

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // If you se this error: "You are calling concat on a terminating link, which will have no effect"
    // That is because this order is incorrect. Usually httpLink should be the last one.
    link: from([attachEnvVariablesToQueries, errorHandlerLink, httpLink]),
    cache: new InMemoryCache()
  });
};

export const initializeApollo = (
  initialState: NormalizedCacheObject | null = null,
  client?: ApolloClient<NormalizedCacheObject>
) => {
  const _apolloClient = apolloClient ?? client ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))
      ]
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const apolloHOC = getApolloHOC(initializeApollo);
