/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloLink } from 'react-apollo';
import env from '../../env';

export const attachEnvVariablesToQueries = new ApolloLink((operation, forward) => {
  // Get the root operation definition
  const def: any = operation.query.definitions.find((x) => x.kind === 'OperationDefinition');

  const context = operation.getContext();

  // If there's any and there are variables
  if ((!context.endpoint || context.endpoint !== 'cms') && def && def.variableDefinitions) {
    // Get the list of variable names for the current operation
    const defVariableNames = def.variableDefinitions
      .map((x: any) => x.variable)
      .map((x: any) => x.name)
      .map((x: any) => x.value);

    // // If there's no variable named envClientId
    // if (!defVariableNames.some((x: string) => x === 'envClientId')) {
    //   // Add the envClientId variable to the query
    //   def.variableDefinitions.push({
    //     kind: 'VariableDefinition',
    //     variable: {
    //       kind: 'Variable',
    //       name: {
    //         kind: 'Name',
    //         value: 'envClientId'
    //       }
    //     },
    //     type: {
    //       kind: 'NonNullType',
    //       type: {
    //         kind: 'NamedType',
    //         name: {
    //           kind: 'Name',
    //           value: 'String'
    //         }
    //       }
    //     },
    //     directives: []
    //   });
    // }

    // If there's a variable named envClientId
    if (defVariableNames.some((x: string) => x === 'envClientId')) {
      // Attach the clientID in the env to the query
      operation.variables = {
        envClientId: env().NEXT_PUBLIC_CLIENT_ID,
        ...operation.variables
      };
    }
  }

  return forward(operation);
});
