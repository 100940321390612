// Ref: https://reactjs.org/docs/context.html
import dayjs from 'dayjs';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import locales, { dayjsLocaleMap, defaultLocale, LocaleEnum } from '../../../locales';

type LocaleContext = {
  /**
   * The current locale the user is at
   */
  locale: LocaleEnum;
  /**
   * The current locale for the dayjs
   */
  dayjsLocale: string;
  /**
   * Updates the locale
   */
  setLocale: (locale: LocaleEnum) => void;
  hasProvider: boolean;
};

const initialState: LocaleContext = {
  locale: defaultLocale,
  dayjsLocale: dayjsLocaleMap[defaultLocale],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLocale: () => {},
  hasProvider: false
};

const LocaleContext = React.createContext<LocaleContext>(initialState);

type LocaleProviderProps = { locale: LocaleEnum };

/**
 * The Locale provider for its react context
 */
export const LocaleProvider: React.FC<LocaleProviderProps> = ({ locale: propLocale, children }) => {
  // Get the locale from the query
  const {
    query: { locale: queryLocale },
    push
  } = useRouter();

  // Only run this once at startup
  const [finalLocale, isGivenLocaleValid] = useMemo(() => {
    // Prefer the locale in the router, if not available, get the locale given as prop, lastly, get the initial state locale, which is the default locale
    const locale = (queryLocale as LocaleEnum | undefined) || propLocale || initialState.locale;

    if (locale && Object.keys(locales).findIndex((x) => x === locale) === -1) {
      console.warn(
        `LocaleProvider: Missing locale data for locale: "${queryLocale}". Using default locale: "${defaultLocale}" as fallback.`
      );
      return [defaultLocale, false];
    } else {
      return [locale, true];
    }
  }, [queryLocale, propLocale]);

  const [locale, setLocale] = useState<LocaleContext['locale']>(finalLocale);
  const [dayjsLocale, setDayjsLocale] = useState<LocaleContext['dayjsLocale']>(dayjsLocaleMap[locale]);

  useEffect(() => {
    if (!isGivenLocaleValid) push('/[locale]', `/${locale}`);
    else {
      if (locale && process.browser) {
        cookie.set('locale', locale, { expires: 1 });
      }

      const updateDayJsLocale = async () => {
        const newDayjsLocale = dayjsLocaleMap[locale];

        if (locale && newDayjsLocale && newDayjsLocale !== dayjsLocale) {
          // Just requires the locale which will add it to the available locale list.
          // Which then will update all usages of "useLocalizedDayjs"
          // We do it here instead of the dayjs local
          await import(`dayjs/locale/${newDayjsLocale}.js`);

          // This will update the locale globally, and the hook will update all instances by returning a new instance
          dayjs.locale(newDayjsLocale);

          setDayjsLocale(newDayjsLocale);
        }
      };

      updateDayJsLocale();
    }
  }, [locale, dayjsLocale, isGivenLocaleValid, push]);

  return (
    <LocaleContext.Provider
      value={{
        hasProvider: true,
        locale,
        setLocale,
        dayjsLocale
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => useContext(LocaleContext);

/**
 * A hook that returns the current locale
 */
export const useLocale = () => {
  const { locale } = useLocaleContext();
  return useMemo(() => locale, [locale]);
};

/**
 * A hook that returns the current locale for dayjs
 */
export const useDayjsLocale = () => {
  const { dayjsLocale } = useLocaleContext();
  return useMemo(() => dayjsLocale, [dayjsLocale]);
};
